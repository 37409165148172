import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { PackageMetadata } from '@happeo/docs-tools';
import pkg from "../package.json";
import { Breadcrumbs } from '../src/index';
import { IconChevronRight, IconArrowForward, IconLauncher, IconLanguage } from "@happeouikit/icons";
import { ButtonSecondary, ButtonPrimary } from "@happeouikit/buttons";
import { Card } from "@happeouikit/card";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "breadcrumbs"
    }}>{`Breadcrumbs`}</h1>
    <PackageMetadata pkg={pkg} mdxType="PackageMetadata" />
    <p>{`Breadcrumbs allow users to make selections from a range of values.`}</p>
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic Usage`}</h2>
    <h4 {...{
      "id": "pass-the-children-to-the-breadcrumbs-component"
    }}>{`Pass the `}<inlineCode parentName="h4">{`children`}</inlineCode>{` to the Breadcrumbs component.`}</h4>
    <Playground __position={1} __code={'<Breadcrumbs>\n  <span>HappeoUIKit</span>\n  <span>Packages</span>\n  <span>Breadcrumbs</span>\n</Breadcrumbs>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      Breadcrumbs,
      IconChevronRight,
      IconArrowForward,
      IconLauncher,
      IconLanguage,
      ButtonSecondary,
      ButtonPrimary,
      Card,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <Breadcrumbs mdxType="Breadcrumbs">
        <span>HappeoUIKit</span>
        <span>Packages</span>
        <span>Breadcrumbs</span>
    </Breadcrumbs>
    </Playground>
    <h2 {...{
      "id": "custom-separator"
    }}>{`Custom separator`}</h2>
    <h4 {...{
      "id": "pass-the-separator-prop-to-the-component"
    }}>{`Pass the `}<inlineCode parentName="h4">{`separator`}</inlineCode>{` prop to the component.`}</h4>
    <Playground __position={2} __code={'<Breadcrumbs separator={<IconChevronRight />}>\n  <span>HappeoUIKit</span>\n  <span>Packages</span>\n  <span>Breadcrumbs</span>\n  <span>IconChevronRight</span>\n</Breadcrumbs>\n<Breadcrumbs separator={<IconArrowForward />}>\n  <span>HappeoUIKit</span>\n  <span>Packages</span>\n  <span>Breadcrumbs</span>\n  <span>IconArrowForward</span>\n</Breadcrumbs>\n\n<Breadcrumbs separator={<IconLauncher />}>\n  <span>HappeoUIKit</span>\n  <span>Packages</span>\n  <span>Breadcrumbs</span>\n  <span>IconLauncher</span>\n</Breadcrumbs>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      Breadcrumbs,
      IconChevronRight,
      IconArrowForward,
      IconLauncher,
      IconLanguage,
      ButtonSecondary,
      ButtonPrimary,
      Card,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <Breadcrumbs separator={<IconChevronRight mdxType="IconChevronRight" />} mdxType="Breadcrumbs">
        <span>HappeoUIKit</span>
        <span>Packages</span>
        <span>Breadcrumbs</span>
        <span>IconChevronRight</span>
    </Breadcrumbs>
      <Breadcrumbs separator={<IconArrowForward mdxType="IconArrowForward" />} mdxType="Breadcrumbs">
    <span>HappeoUIKit</span>
    <span>Packages</span>
    <span>Breadcrumbs</span>
    <span>IconArrowForward</span>
      </Breadcrumbs>

      <Breadcrumbs separator={<IconLauncher mdxType="IconLauncher" />} mdxType="Breadcrumbs">
    <span>HappeoUIKit</span>
    <span>Packages</span>
    <span>Breadcrumbs</span>
    <span>IconLauncher</span>
      </Breadcrumbs>
    </Playground>
    <h2 {...{
      "id": "customization"
    }}>{`Customization`}</h2>
    <h4 {...{
      "id": "use-different-child-components-to-customize-the-view"
    }}>{`Use different child components to customize the view.`}</h4>
    <Playground __position={3} __code={'<Breadcrumbs separator={<IconChevronRight />}>\n  <ButtonSecondary type=\"dark\" text=\"HappeoUIKit\" icon={IconLauncher} />\n  <ButtonSecondary text=\"Packages\" />\n  <ButtonSecondary text=\"Breadcrumbs\" />\n  <ButtonPrimary type=\"success\" text=\"IconLauncher\" icon={IconLanguage} />\n</Breadcrumbs>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      Breadcrumbs,
      IconChevronRight,
      IconArrowForward,
      IconLauncher,
      IconLanguage,
      ButtonSecondary,
      ButtonPrimary,
      Card,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <Breadcrumbs separator={<IconChevronRight mdxType="IconChevronRight" />} mdxType="Breadcrumbs">
        <ButtonSecondary type="dark" text="HappeoUIKit" icon={IconLauncher} mdxType="ButtonSecondary" />
        <ButtonSecondary text="Packages" mdxType="ButtonSecondary" />
        <ButtonSecondary text="Breadcrumbs" mdxType="ButtonSecondary" />
        <ButtonPrimary type="success" text="IconLauncher" icon={IconLanguage} mdxType="ButtonPrimary" />
    </Breadcrumbs>
    </Playground>
    <h2 {...{
      "id": "custom-root-component"
    }}>{`Custom root component`}</h2>
    <h4 {...{
      "id": "pass-the-component-prop-to-the-component"
    }}>{`Pass the `}<inlineCode parentName="h4">{`Component`}</inlineCode>{` prop to the component.`}</h4>
    <Playground __position={4} __code={'<Breadcrumbs Component={Card} separator={<IconChevronRight />}>\n  <span>HappeoUIKit</span>\n  <span>Packages</span>\n  <span>Breadcrumbs</span>\n  <span>IconChevronRight</span>\n</Breadcrumbs>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      Breadcrumbs,
      IconChevronRight,
      IconArrowForward,
      IconLauncher,
      IconLanguage,
      ButtonSecondary,
      ButtonPrimary,
      Card,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <Breadcrumbs Component={Card} separator={<IconChevronRight mdxType="IconChevronRight" />} mdxType="Breadcrumbs">
        <span>HappeoUIKit</span>
        <span>Packages</span>
        <span>Breadcrumbs</span>
        <span>IconChevronRight</span>
    </Breadcrumbs>
    </Playground>
    <h2 {...{
      "id": "breadcrumbs-api"
    }}>{`Breadcrumbs API`}</h2>
    <Props of={Breadcrumbs} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      